@font-face {
  font-family: "GTWalsheimLC";
  src: url("../../../../assets/fonts/GTWalsheimLC-Rg.ttf");
}

@font-face {
  font-family: "GTWalsheimLC";
  src: url("../../../../assets/fonts/GTWalsheimLC-Bd.ttf");
  font-weight: bold;
}
